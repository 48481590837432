import './PhotoGallery.css';
import React from 'react';
import ImageGallery from 'react-image-gallery';

const PhotoGallery = () => {
  const images = [
    {
      original: require('../../assets/baspare-resimler/10.jpg'),
      thumbnail: require('../../assets/baspare-resimler/10.jpg'),
    },
    {
      original: require('../../assets/kaval-agizlik-resimler/1.jpg'),
      thumbnail: require('../../assets/kaval-agizlik-resimler/1.jpg'),
    },
    {
      original: require('../../assets/ney-resimler/1.jpg'),
      thumbnail: require('../../assets/ney-resimler/1.jpg'),
    },
    {
      original: require('../../assets/other/1.jpg'),
      thumbnail: require('../../assets/other/1.jpg'),
    },
    {
      original: require('../../assets/other/2.jpg'),
      thumbnail: require('../../assets/other/2.jpg'),
    },
    {
      original: require('../../assets/other/3.jpg'),
      thumbnail: require('../../assets/other/3.jpg'),
    },
    {
      original: require('../../assets/other/4.jpg'),
      thumbnail: require('../../assets/other/4.jpg'),
    },
    {
      original: require('../../assets/other/5.jpg'),
      thumbnail: require('../../assets/other/5.jpg'),
    },
  ];

  return (
    <>
      <h1 className="header">Fotoğraflar</h1>
      <div className="imageGalleryContainer">
        <ImageGallery
          items={images}
          lazyLoad={true}
          thumbnailPosition="right"
          showPlayButton={false}
          autoPlay={true}
          showBullets={false}
          additionalClass="photoGallery"
          showNav={false}
        />
      </div>
    </>
  );
};

export default PhotoGallery;
