import React from "react";
import Slider2 from "../../components/Slider2/Slider2";
import ArticleList from "../../components/articleList/ArticleList";
import PhotoGallery from "../../components/photoGallery/PhotoGallery";
import ContactArea from "../../components/contactArea/ContactArea";

const MainPage = () => {
  return (
    <div>
      {/* <Slider heading="Example Slider" slides={slideData} starterCount={3} /> */}
      <Slider2 />
      <div id="yazilar">
        <ArticleList />
      </div>
      <div id="galeri">
        <PhotoGallery />
      </div>
      <div id="iletisim">
        <ContactArea />
      </div>
    </div>
  );
};

export default MainPage;
