import React from "react";
import "./ContactArea.css";

const ContactArea = () => {
  return (
    <div className="contact-area">
      <div className="contact-area-container">
        <h1 className="header">İletişim</h1>
        <div className="contact-area__content">
          <div className="contact-area-right">
            <div className="Address">
              <h2>Adres</h2>
              <p>
                Osmanağa, Kırtasiyeci Sk. Ekşioğlu iş hanı No:49 Altıyol, 34714
                Kadıköy/İstanbul
              </p>
            </div>
            <div className="Telefon">
              <h2>Telefon</h2>
              <p>
                <a
                  href="tel:5322040316"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  +90 532 204 03 16
                </a>
              </p>
            </div>
            <div className="Email">
              <h2>E-posta</h2>
              <p>
                <a
                  href="mailto:bilalkabat@gmail.com"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  bilalkabat@gmail.com
                </a>
              </p>
            </div>
          </div>
          <div className="contact-area-left">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.598187357964!2d29.025701976424415!3d40.990278620532465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac5c056e96bb5%3A0xcf7fec1590479262!2zQXTDtmx5ZSBCYcWfcGFyZQ!5e0!3m2!1str!2str!4v1719563665104!5m2!1str!2str"
              width="600"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google-Maps"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactArea;
