import React from "react";
import "./Footer.css"; // CSS dosyamızı import ediyoruz

const Footer = () => {
  return (
    <div className="footer-container">
      <footer className="footer">
        <div className="footer-section">
          <div className="about-text">
            <h4>Hakkımda</h4>
            <p>
              Bilal Kabat, TC. Kültür ve Turizm Bakanlığı Geleneksel El
              Sanatları Somut Olmayan Kültür Mirası Taşıyıcısıdır. Başpare, Ney,
              Tesbih ve Takı gibi özel ve kültürel değeri yüksek eşyaların
              üretimini gerçekleştirmektedir. Her bir eser, atölyede yoğun bir
              işçilik sonucu geçmişin zarafetini ve kültürel mirasın
              zenginliğini yansıtmak için büyük bir özenle hazırlanır.
              <br /> <br />
              Eserleri, titizlik ve sevgiyle hazırlanmış olup, geleneksel
              sanatın yaşatılmasına katkı sunmaktadır.
            </p>
            <br />
          </div>
        </div>
        <div className="width"></div>
        <div className="footer-section">
          <h4>Yasal Uyarı</h4>
          <p>
            Sitemizdeki bilgilerin ve resimlerin izinsiz kullanılması halinde
            yasal işlem başlatılır.
          </p>
          <br />
          <p>
            <strong>Email:</strong> bilalkabat@gmail.com
          </p>
          <p>
            <strong>Telefon:</strong> +90 532 204 03 16
          </p>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>
          Designed By <a href="https://linkedin.com/in/emin-arik">Emin Arık</a>{" "}
          | Copyright © 2024 <a href="/">Baspare.com</a>, Tüm Hakları Saklıdır.
        </p>
      </div>
    </div>
  );
};

export default Footer;
