// File: src/components/ArticleList.js
import React from "react";
import ImageCard from "../imageCard/ImageCard";
import "./ArticleList.css";

const articles = [
  {
    title: "BAŞPARE",
    imageSrc: require("../../assets/baspare-resimler/10.jpg"),
    description:
      'Başpare kelimesine sözlük anlamı olarak baktığımızda "baş parçası" anlamına gelmektedir Neylerin üflenen kısmındaki parçaya verilen isimdir...',
    blogIndex: 1,
  },
  {
    title: "Kaval Ağızlığı",
    imageSrc: require("../../assets/kaval-agizlik-resimler/1.jpg"),
    description:
      "Kavalın üst ucuna takılır Kaval sesinin daha net,güzel ve temiz çıkmasını sağlayan en önemli parçasıdır...",
    blogIndex: 2,
  },
  {
    title: "Ney",
    imageSrc: require("../../assets/ney-resimler/1.jpg"),
    description:
      "Dinle, bu ney neler hikâyet eder, ayrılıklardan nasıl şikâyet eder....",
    blogIndex: 3,
  },
  // {
  //   title: 'Tesbih',
  //   imageSrc: require('../../assets/img-01.png'),
  //   description:
  //     'Traveling is an enriching experience that opens up new horizons, exposes us to different cultures, and creates memories that last a lifetime...',
  //   blogIndex: 3,
  // },
];

const ArticleList = () => {
  return (
    <>
      <h1 className="header mobile-hide">Yazılar</h1>
      <div className="article-list">
        {articles.map((article, index) => (
          <ImageCard
            key={index}
            title={article.title}
            imageSrc={article.imageSrc}
            description={article.description}
            blogIndex={article.blogIndex}
          />
        ))}
        {/* <button className="view-all-button">Yazıları Görüntüle</button> */}
      </div>
    </>
  );
};

export default ArticleList;
