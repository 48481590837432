import React from 'react';
import { useParams } from 'react-router-dom';
import './BlogPage.css';
import BasparePage from './blogs/baspare';
import KavalAgizligi from './blogs/kaval-agizligi';

const BlogPage = () => {
  const { id } = useParams();

  if (id === '1') {
    return <BasparePage />;
  } else if (id === '2') {
    return <KavalAgizligi />;
  } else {
    return (
      <div
        style={{
          width: '70%',
          height: '45vh',
          margin: 'auto',
          textAlign: 'center',
          backgroundColor: '#fff',
        }}
      >
        Bu sayfa henüz yapım aşamasındadır.
      </div>
    );
  }
};

export default BlogPage;
