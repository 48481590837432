import React from "react";
import "./kaval-agizligi.css";

const KavalAgizligi = () => {
  const imageList = [
    require("../../../assets/kaval-agizlik-resimler/1.jpg"),
    require("../../../assets/kaval-agizlik-resimler/2.jpg"),
    require("../../../assets/kaval-agizlik-resimler/3.jpg"),
    require("../../../assets/kaval-agizlik-resimler/4.jpg"),
    require("../../../assets/kaval-agizlik-resimler/5.jpg"),
    require("../../../assets/kaval-agizlik-resimler/6.jpg"),
    require("../../../assets/kaval-agizlik-resimler/7.jpg"),
  ];

  return (
    <div className="blogPage">
      <h1>Kaval Ağızlığı</h1>
      <h2>Manda Boynuzu Kaval Ağızlıkları</h2>
      <div className="image-container">
        <img src={imageList[0]} alt="Manda Boynuzu" />
        <img src={imageList[1]} alt="Manda Boynuzu Kesimi" />
        <img src={imageList[2]} alt="Manda Boynuzu Kesilmiş Hali" />
        <img src={imageList[3]} alt="Boynuzu Ölçülendirme" />
        <img src={imageList[4]} alt="Başpareyi Tornalarken" />
        <img src={imageList[5]} alt="Başpare Zımpara Yapılırken" />
        <img src={imageList[6]} alt="Başpareye Hazne Yapılırken" />
      </div>
      <hr className="divider" />
      <h2>Kaval Ağızlığında Boynuz kullanımının Önemi</h2>
      <p>
        Nefesli enstrumanlardaki ağız yapısı, özellikle kaval ve ney gibi çalımı
        zor olan ,düzgün ve temiz ton çıkarılması uzun çalışma ve zaman alan
        enstrumanlarda çok önemlidir. Sesin çıkarılması için hiçbir yardımcı
        unsurun bulunmadığı boş bir ağaç borudan oluşan kavalda ses, üflenen
        nefesin kavalın karşı iç duvarına çarpmasıyla oluşan titreşimlerden elde
        edilmektedir.Bundan dolayı, nefesin çarptığı bu ilk nokta çok önemli
        olmakta ve titreşimin alt tarafa doğru mümkün olduğunca daha net ve
        kaybolmadan ulaşması için ,gövdeyi oluşturan ağaçtan daha sert ve
        pürüzsüz olması gerekmektedir.Çünkü elimizdeki ağaç ne kadar sert olursa
        olsun canlı ve gözenekli bir malzeme olmasından dolayı, üflenen havanın
        az da olsa gözeneklerin içinde kaybolma riski vardır.İşte bu riski
        mümkün olduğunca aza indirmek ve güzel bir ton almak için ağızlık olarak
        sert bir maddeye ihtiyaç duyulmaktadır.Burada ki en önemli etken
        kullanılan ağızlığın doğal ve organik bir madde olmasıdır.Bu sebeple
        boynuz hem doğada kendiliğinden gelişen doğal,organik hem de sert ve
        pürüzsüz olmasından dolayı ağızlık olarak kullanabileceğimiz elimizdeki
        natürel bir maddedir. Sazlarda ağızlık olarak kullanılan plastik
        maddeler hem kimyasal bileşimler içerdiğinden dolayı sağlık olarak
        elverişli değil hem de yumuşak bir madde olmasından dolayı istenilen ton
        çıkarılmasında verimli olmamaktadır. Bu sebelerden dolayı
        enstrumanlarımızda boynuzdan yapılan ağızlıkları kullanmamız çok daha
        uygundur.
        <br />
        <br />
        MURAT TOROMAN
      </p>
    </div>
  );
};

export default KavalAgizligi;
