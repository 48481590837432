import React from "react";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import { useEffect, useState } from "react";
import MainPage from "./pages/mainPage/MainPage";
import BlogPage from "./pages/blogPage/BlogPage";

const App = () => {
  const [isVisible, setIsVisible] = useState(true);
  console.log(isVisible);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/blog/:id" element={<BlogPage />} />
        </Routes>
        <ScrollToTop />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
