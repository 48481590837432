import React, { useState } from "react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import "./Header.css";
import Logo from "../../assets/logo.png";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header>
      <div className="container" style={{ marginBottom: "1rem" }}>
        <div className="logo">
          <a href="/">
            <img src={Logo} alt="Logo" />
          </a>
          {/* <div className="menu-toggle" onClick={toggleMenu}>
            <div className={isOpen ? 'hamburger open' : 'hamburger'}>
              <RxHamburgerMenu className="hamburger-icon" />
            </div>
          </div> */}
        </div>
        <nav className={isOpen ? "nav-menu nav-menu-active" : "nav-menu"}>
          <ul>
            <li>
              <ScrollLink
                to="anasayfa"
                smooth={true}
                duration={500}
                onClick={closeMenu}
                className="nav-link"
              >
                Ana Sayfa
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="yazilar"
                smooth={true}
                duration={500}
                onClick={closeMenu}
                className="nav-link"
              >
                Yazılar
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="galeri"
                smooth={true}
                duration={500}
                onClick={closeMenu}
                className="nav-link"
              >
                Galeri
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="iletisim"
                smooth={true}
                duration={500}
                onClick={closeMenu}
                className="nav-link"
              >
                İletişim
              </ScrollLink>
            </li>
          </ul>
        </nav>
        <div className="social-icons">
          <a
            href="https://www.instagram.com/bilalkabat"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=905322040316"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
          </a>
        </div>
        {/* <HeaderPanel isOpen={isOpen} onClose={closeMenu} /> */}
      </div>
    </header>
  );
}

export default Header;
