import React from "react";
import ReactCarousel from "react-carousel-animated";
import images from "./images";
import "react-carousel-animated/dist/style.css";
import "./Slider2.css";

const Slider2 = () => {
  return (
    <div className="slider-container mobile-hide">
      <ReactCarousel
        carouselConfig={{
          transform: {},
          autoPlay: true,
          autoPlayInterval: 2, // 5 saniye
        }}
        itemBackgroundStyle={{
          borderRadius: "3px",
        }}
        containerBackgroundStyle={{}}
        itemMaxWidth={50}
        carouselHeight="400px"
      >
        {images[0].map((image, index) => (
          <div key={index}>
            <img
              src={image.src}
              alt="test"
              style={{
                maxHeight: "300px",
                maxWidth: "400px",
                borderRadius: "12px",
              }}
            />
          </div>
        ))}
      </ReactCarousel>
    </div>
  );
};

export default Slider2;
