const images = [
  [
    {
      src: require("../../assets/baspare-resimler/10.jpg"),
      headline: "Başpare İmalatı",
      description: "lorem ipsum dolar sit amet Başpare için",
    },
    {
      src: require("../../assets/tesbih-resimler/2.png"),
      headline: "Tesbih İmalatı",
      description: "lorem ipsum dolar sit amet Tesbih için",
    },
    {
      src: require("../../assets/ney-resimler/1.jpg"),
      headline: "Ney İmalatı",
      description: "lorem ipsum dolar sit amet Ney için",
    },
    {
      src: require("../../assets/kaval-agizlik-resimler/1.jpg"),
      headline: "Kaval Ağızlığı İmalatı",
      description: "lorem ipsum dolar sit amet Başpare için",
    },
    {
      src: require("../../assets/other/1.jpg"),
      headline: "Kuksa",
      description: "lorem ipsum dolar sit amet Başpare için",
    },
    {
      src: require("../../assets/other/2.jpg"),
      headline: "Ebru Bizi",
      description: "lorem ipsum dolar sit amet Başpare için",
    },
    {
      src: require("../../assets/other/3.jpg"),
      headline: "Şef Batonu",
      description: "lorem ipsum dolar sit amet Başpare için",
    },
    {
      src: require("../../assets/other/4.jpg"),
      headline: "Mızraplar",
      description: "lorem ipsum dolar sit amet Başpare için",
    },
    {
      src: require("../../assets/other/5.jpg"),
      headline: "Takılar",
      description: "lorem ipsum dolar sit amet Başpare için",
    },
  ],
  [
    {
      src: "https://via.placeholder.com/720x500",
    },
    {
      src: "https://via.placeholder.com/720x500",
    },
    {
      src: "https://via.placeholder.com/720x500",
    },
  ],
  [
    ...[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => ({
      src: "https://via.placeholder.com/720x500",
    })),
  ],
  [
    ...[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => ({
      src: "https://via.placeholder.com/1280x720",
    })),
  ],
  [
    ...[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => ({
      src: "https://via.placeholder.com/320x820",
    })),
  ],
  [
    ...[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => ({
      src: "https://via.placeholder.com/820x320",
    })),
  ],
];

export default images;
