import React from "react";
import "./baspare.css";

const BasparePage = () => {
  const imageList = [
    require("../../../assets/baspare-resimler/1.jpg"),
    require("../../../assets/baspare-resimler/2.jpg"),
    require("../../../assets/baspare-resimler/3.jpg"),
    require("../../../assets/baspare-resimler/4.jpg"),
    require("../../../assets/baspare-resimler/5.jpg"),
    require("../../../assets/baspare-resimler/6.jpg"),
    require("../../../assets/baspare-resimler/7.jpg"),
    require("../../../assets/baspare-resimler/8.jpg"),
    require("../../../assets/baspare-resimler/9.jpg"),
  ];

  return (
    <div className="blogPage">
      <h1>Başpare</h1>
      <h2>Başpare Nedir?</h2>
      <p>
        Başpare kelimesine sözlük anlamı olarak baktığımızda "baş parçası"
        anlamına gelmektedir. Neylerin üflenen kısmındaki parçaya verilen
        isimdir.
        <sup>[1]</sup> Cafer Açın'ın Ney Yapım Sanatı ve Sanatkarları eserinde
        başpare maddesini şu şekilde açıkladığını görmekteyiz: "Ney'in üflenen
        kısmına boynuzdan yapılmış bir ağızlık takılır ve buna başpare adı
        verilir. Seslerin sağlam ve temiz çıkmasını bu kısım sağlar."
        <sup>[2]</sup> Bir diğer kaynakta ise başpare, şu şekilde
        tanımlanmaktadır: "Başpare: Ney'in kamışının baş tarafına fildişi,
        abanoz yahut kemikten yapılarak takılan parçaya verilen addır."
        <sup>[3]</sup>
      </p>
      <hr className="divider" />
      <h2>Başpare Yapım Aşamaları</h2>
      <div className="image-container">
        <img src={imageList[0]} alt="Manda Boynuzu" />
        <img src={imageList[1]} alt="Manda Boynuzu Kesimi" />
        <img src={imageList[2]} alt="Manda Boynuzu Kesilmiş Hali" />
        <img src={imageList[3]} alt="Boynuzu Ölçülendirme" />
        <img src={imageList[4]} alt="Başpareyi Tornalarken" />
        <img src={imageList[5]} alt="Başpare Zımpara Yapılırken" />
        <img src={imageList[6]} alt="Başpareye Hazne Yapılırken" />
        <img src={imageList[7]} alt="Başpareye Cila Yapılırken" />
        <img src={imageList[8]} alt="Başparenin Bitmiş Hali" />
      </div>
      <hr className="divider" />
      <h2>Başpare Yapımında Kullanılan Malzemeler Nelerdir?</h2>
      <p>
        Başparenin tarihi sürecinde izlediğimiz kadarıyla yapımında kullanılan
        en önemli malzeme “boynuz”dur. Özellikle başpare yapımında manda boynuzu
        gibi yapısı sıkı ve sert boynuzlar tercih edilmiştir. Bunun dışında
        boynuz türevi diş (fildişi) ve gergedan boynuzu gibi hayvansal
        malzemeler de başpare imalatında kullanılmaktadır. Bunların dışında
        başpare üretimi için ağaç malzemeler de kullanılmaktadır. Genellikle
        yapısı sert ve dokuları sıkı olan ağaçlar tercih edilmektedir. Örnek
        verecek olursak: Şimşir, abanoz, pelesenk vb.
        <br />
        <br />
        Şimdiye kadar saydığımız malzemeler dışında pek çok farklı malzeme
        başpare yapımında kullanılmıştır. Bunlara da örnek verecek olursak:
        Kimyasal içerikli malzemeler, uçak camı vb. Bir de alpakadan (metal
        alaşımlı bir malzeme) yapılmış, Neyzen Tevfik’in kullandığı sanılan bir
        başpare bulunmaktadır. Manda boynuzunun organik yapısı, bakteri
        barındırmaması, nem ve ısıyla imtizaç olması, yapısı icabı titreşime
        olan duyarlılığı ve en önemlisi neyzenin dudak temasındaki verdiği
        hissiyat göz önünde tutulduğunda, manda boynuzu, başpare yapımında en ön
        planda yer almaktadır. Son yıllarda ülkemizde manda boynuzu gibi
        özellikle kullanılması gereken malzemelerin çok azalmış olması ve
        temininin zorluğundan yola çıkılarak, tarafımızca düşünüp tasarlanmış
        çift parçalı (kakmalı) başparelerle bu yolda önemli bir mesafe kat etmiş
        bulunuyoruz. Esasında bu tasarım, işlevselliği açısından başparenin
        özelliğini ve formunu bozmadan tasarruflu, görsel açıdan zengin, tını
        açısından çeşitlilik arz eden bir sonuca ulaşılmıştır. Tasarıma
        çeşitlilik açısından katkıda bulunarak çalışmalarımızı sürdürüyoruz. Bu
        çalışmalarımızda da boynuz ve kıymetli ağaçları kullanmaktayız. Yukarıda
        belirttiğimiz gibi, işlevsellik açısından başparenin dudağa değen ağız
        kısmı ve havanın geçtiği bölüm tamamen manda boynuzundan olmaktadır.
        Kullanılan diğer ağaç malzemeler ise başparenin kalak kısmını
        oluşturmaktadır. Dolayısıyla, işleyiş açısından bakıldığında çift
        parçalı yaptığımız başpareler tamamen tek parçalı manda boynuzundan
        yapılan başpareler gibidir. Sonuç olarak, bütün bu malzemelerin içinde
        en çok tercih edileni manda boynuzu olmuştur. Tarihi gerçekler de bize
        bunu göstermektedir. İşte az önce saydığımız sebeplerden dolayı,
        yüzyıllardan beri gelen geleneği yaşatmak adına, manda boynuzunu başpare
        yapımında tercih etmeyi bir görev sayıyoruz.
      </p>
      <hr className="divider" />
      <h2>Ney Üflerken Niçin Başpare Kullanılmaktadır?</h2>
      <p>
        Bilindiği üzere pek çok kültürde ney ve benzeri nefesli sazlar
        üflenmektedir. Özellikle Arap Müziği’nde neyin yapısına çok benzeyen,
        aynı kamıştan yapılmış nefesli sazlar bulunmaktadır. Bunlar genellikle
        başparesiz üflenmekte olup, bugün bizim müziğimizde de neyi başparesiz
        olarak kullanan bazı icracılar bulunmaktadır. Başpare kullanımı aslen
        tartışmalı bir konu olup, tarihten gelen inkar edilemeyecek bir
        gerçekliğe sahiptir. Başpare kullanımının birkaç önemli unsuru olduğunu
        görüyoruz: Neyden iyi bir tını ve ses elde etmeye yarayan bir parçadır.
        Ney, hüda-i nabit bir sazdır. En büyük özelliği, bugüne kadar yapılmış
        olan neylerin hiç birinin birbirine benzememesidir. Bundan sonra
        yapılacak olan neyler de hiçbir şekilde birbirine benzemeyecektir. Adeta
        vahdet-i vücud felsefesi gibi, birbirine benzememezlik hali, doğal
        olarak neyin çap ve kuturunda farklılıklara neden olmaktadır. İşte
        başparenin en önemli özelliği, çeşitli çap ve kuturdaki kamışların,
        üflenmesi gereken kısmını tek bir ölçüye sabitlemesidir. Bu, neyzenin
        değişik akort ve ebatlardaki neyleri üflemesi durumunda karşılaşacağı
        dudak pozisyonu farklılığını ortadan kaldırmaktadır. Başparenin ne zaman
        kullanılmaya başladığı tarihsel süreç içerisinde net olarak
        bilinmemektedir. Minyatürler vb. kaynaklar bize bu konu hakkında kesin
        bir bilgi verememektedir. Elimizde olan eski neylerin büyük bir kısmı,
        mansur, şah ve davut gibi çaplı ve büyük neylerdir. Bu da kanımızca
        başpare kullanımını zorunlu kılmıştır. Eskiden gelen bilgilere göre
        akortlar şah ve mansur oktavları olduğu için, bu akorttaki neyler çokça
        kullanılmıştır. Nısfiyeci İhsan Efendi ile başlayan nısfiye kayıtları
        ise ancak 19. yüzyıla tekabül etmektedir. Ney, tarihi süreci içerisinde
        tekkeden çıkmamış olup, bugünkü ney icrası anlayışı yaklaşık 100 seneden
        fazla değildir. Diğer bir husus da, başparenin seyyar bir parça
        olmasıdır. Bu hal, gerektiğinde küçük akort değişiklikleri
        yapılabilmesine imkân tanımaktadır. Bu, daha çok, neyi pestleştirme
        imkânıdır. Başparesiz üflenen ney, maruz kaldığı nemden dolayı, bir
        müddet sonra özelliğini bir miktar yitirmekte olup, dudağa olan
        hassasiyeti de bozulmaktadır. Başparenin bir vazifesi de bu durumu
        ortadan kaldırmaktır. Başpare, sazın görünümünü tamamlayan estetik bir
        unsurdur.
      </p>
      <hr className="divider" />
      <h2>Başparenin Teknik Yapısı</h2>
      <p>
        Kanaatimizce, günümüzde başpareye getirilmeye çalışılan standart,
        fazlaca gerçeklik taşımamakta, daha çok, hızlı üretim ve ticari başarı
        kaygısıyla öne çıkmaktadır. Yukarıda belirttiğimiz üzere, neylerin
        birbirine benzememek keyfiyetini, başparelerde de uygulanması
        gerektiğini düşünüyoruz. Bu yüzden, çeşitli kaynaklarda verilen ölçü
        bilgileri tam anlamıyla bir değer taşımamaktadır. Çünkü, ney olacak
        kamışların üzerine takılacak başpareler farklı özellikler taşımalı ve
        kamışların genel görünümüne de uygun olmalıdır. Her neyzenin dudak
        yapısı, üfleme açısı ve başpareye dudak koyuşu farklılıklar arz
        etmektedir. Dolayısıyla başpare yapılırken, neyzenin arzu ve isteklerini
        göz önünde bulundurmak gerekir. Bu durum, tabii olarak, başparenin dış
        ve iç yapısında farklılıklar getirmektedir. Örneğin, dış kalak
        eğimleri(yatık, dik, bombeli vb.), ağız üfleme çapı ve şekli(dik,
        memeli, keskin, küt vb.) ve iç yapıda hazne tipi(tam hazne, yarım hazne
        ve haznesiz) neyzenden neyzene farklılık gösterir. Bir de, ayak meselesi
        var. Ayak mesafesi neyzenin isteğine uygun şekilde ayarlanmalıdır.
        Neyzen, ayaksız başpare kullanmayı da tercih edebilir. Bezer şekilde,
        kalak çapı da önem arz etmektedir. Bu açıklamalardan yola çıkarak,
        başpare ölçüleri konusunda bir şema vermeyi uygun bulmuyoruz. Çünkü 20
        mm çapında bir kamışla, 30 mm çapında bir kamışta kullanılan başpare
        aynı olmamalıdır. Sonuç olarak şunu söyleyebiliriz: İyi bir başpare
        öncelikle iyi bir gözlem sonucu ortaya çıkar. Bu görüşlerimiz ışığında
        hocamız Niyazi Sayın'dan aldığımız görgüyü, tekniği ve bilgiyi
        başparelerimize uygulamaya çalışıyoruz. Yeni başlayan neyzen adaylarına
        da tavsiyemiz, yukarıda açıkladığımız hususlar doğrultusunda hareket
        etmeleridir.
      </p>
      <hr className="divider" />
      <h2>Geçmişten Günümüze Başpare Yapımcıları</h2>
      <p>
        Kayıtlı tarihimizde, başpare yapımcıları hakkında maalesef fazlaca bilgi
        bulunmamaktadır. Yalnız, değerli Neyzen büyüğümüz Sencer Derya'dan hocam
        Salih Bilgin'ın aktardığına göre, 19. yüzyılda Enderun'da bulunmuş saray
        neyzenlerinin başparecisi Şeker Usta'dan söz edilmektedir. Bunun
        dışında, Hocamın ilk başparelerini yaptırdığı Kıztaşılı Bektaşi Dedesi
        Yaşar Baba, Kapalıçarşı Bedesten'de Bülent Dölen Usta, Hocamız Kutb-i
        Nay Niyazi Sayın, Neyzen ve Ney Yapımcısı Mahmut Uğurlu Akdoğan, Luthiye
        Fehmi Kılınçer, Selahattin Gürzel, Ney Yapımcısı Ferit Yavuz ve Eymen
        Gürtan, tarihten günümüze gelen başpare yapımcıları içerisinde
        isimlerini tespit edebildiklerimizdir. Günümüzde pek çok ney yapımcısı,
        başpare yapımı ile de uğraşmaktadır. Muhakkak ki tarihi süreci en çok
        bilinen saz olan ney ve başpare, pek çok kişi tarafından yapılmıştır. Ne
        yazık ki pek çoğunun ismini bilememekteyiz. Genellikle bu işe
        profesyonel amaçlı yanaşılmadığı için, münferit pek çok usta tarih
        içinde kaybolmuştur. Hepsini saygı ve rahmetle anıyoruz.
      </p>
      <hr className="divider" />
      <h2>Sonuç</h2>
      <p>
        Klasik Türk Musikisi'nin ve Tasavvuf Musikimiz'in yegâne nefesli sazı
        olan Nây-ı Şerif, her zaman, layık olduğu yerde tutulmayı hak etmiştir.
        Yüzyılları bulan tarihi seyri içinde günümüze kadar ulaşmış bu kıymetli
        değere, bütün gönlümüzce sahip olmalıyız. Dünya üzerinde, felsefesi
        bulunan iki sazdan birisi olan Nây-ı Şerif'in geleneksel yapısını
        bozmamayı, elimizden geldiği kadar, orijinalitesine sadık kalmayı
        kendimize şiar edindik. Bu saza gönül vermiş herkesin aynı hassasiyeti
        göstermesini cân-ı gönülden diliyoruz. Ben de, değerli hocam Salih
        Bilgin'in bana göstermiş olduğu anlayış, ilgi ve alakaya istinaden, bana
        öğrettiği bilgi doğrultusunda çalışmalarımı devam ettirmeyi ve kendisine
        teşekkürü bir borç bilirim.
      </p>
      <hr className="divider" />
      <p>
        <sup>[1]</sup> Devellioğlu, Ferit, Osmanlıca - Türkçe Ansiklopedik
        Lugat, Ankara, s:90
        <br />
        <sup>[2]</sup> Açın, Cafer, Ney Yapım Sanatı ve Sanatçıları, İstanbul,
        2006, s:27
        <br />
        <sup>[3]</sup> Akalın, Mehmet Zeki, Osmanlı Tarih deyimleri ve Terimleri
        Sözlüğü, İstanbul, 1983, c:I, s: 169
      </p>
    </div>
  );
};

export default BasparePage;
