import React from 'react';
import './ImageCard.css';

const ImageCard = ({ title, imageSrc, description, blogIndex }) => {
  return (
    <div className="image-card">
      <img src={imageSrc} alt={title} className="image-card-img" />
      <div className="image-card-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <a href={'/blog/' + blogIndex} className="read-more">
          Devamını Oku &gt;&gt;
        </a>
      </div>
    </div>
  );
};

export default ImageCard;
